.map-wrapper #map, .map-wrapper .map-only-sketch {
  position: absolute;
  top: 0;
  left: 0;
}

.map-wrapper .displayed-canvas-wrapper {
  border: 1px solid red;
  position: absolute;
  bottom: -100px;
  right: 0;
  width: fit-content;
  height: fit-content;
  left: -2000px;  /*left: -600px*/
}

#duplicate-fullsize-canvas {
  border: 2px solid purple;
}

.spinner-overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Optional: prevent interaction while loading */
}

.fancy-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #ccc;
  border-top: 5px solid #0070c0;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-text {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}