
/* input sliders */
.precision-controls-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.precision-controls {
  display: flex;
  height: 100%;
}

.precision-label {
  height: 20px;
  margin-right: 5px;
  font-size: 14px;
}

.precision-slider {
  width: 100px;
}

.precision-text {
  width: 40px;
  height: 20px;
  margin-top: 16px;
  margin-left: 3px;
  border-radius: 6px; 
  border: 1px solid #ccc; 
  padding: 2px 6px;        
  font-size: 14px;     
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 14px;
  background: transparent;
  margin: 20px 0;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none;
}

/* Track */
input[type=range]::-webkit-slider-runnable-track {
  height: 8px;
  background: #A566E8;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.2);
}

/* Thumb */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 22px;
  width: 22px;
  background: #ffffff;
  border: 2px solid #A566E8;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  margin-top: -7px;
  transition: background 0.3s, transform 0.2s ease-in-out;
}

input[type=range]:hover::-webkit-slider-thumb {
  background: #f3f3f3;
  transform: scale(1.1);
}

/* Firefox */
input[type=range]::-moz-range-track {
  height: 8px;
  background: #A566E8;
  border-radius: 10px;
}

input[type=range]::-moz-range-thumb {
  height: 22px;
  width: 22px;
  background: #ffffff;
  border: 2px solid #A566E8;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
}

/* Edge/IE */
input[type=range]::-ms-track {
  height: 8px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #A566E8;
  border-radius: 10px;
}

input[type=range]::-ms-fill-upper {
  background: #A566E8;
  border-radius: 10px;
}

input[type=range]::-ms-thumb {
  height: 22px;
  width: 22px;
  background: #ffffff;
  border: 2px solid #A566E8;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

