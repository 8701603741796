.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  visibility: visible;
}

.overlay-hidden {
  visibility: hidden;
}

.overlay-content-wrapper {
  width: 100%;
  height: 100%;
}

.overlay-content-wrapper > .shape-editor-wrapper {
  width: 100%;
  height: 100%;
  font-weight: 700;
  pointer-events: auto;
  margin: auto;
  position: relative;
}

.overlay-content-wrapper .shape-editor-header {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
}

.overlay-content-wrapper .sketch-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-content-wrapper .shape-editor-controls {
  display: flex;
  flex-direction: column;
}

.overlay-content-wrapper .shape-editor-footer {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  max-width: 100%;
}

.overlay-content-wrapper .sketch-canvas {
  box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.55);
}

.overlay-content-wrapper .shape-editor-footer,
.overlay-content-wrapper .shape-editor-header {
  height: fit-content;
  font-weight: 700;
  border: 1px solid white;
}

.overlay-content-wrapper .shape-editor-options {
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
}

.overlay-content-wrapper .dialog-footer {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.overlay-content-wrapper .shape-editor-options-description,
.overlay-content-wrapper .shape-editor-footer,
.overlay-content-wrapper .shape-editor-header {
  background-color: #333333;
  color: white;
  z-index: 400;
}

.overlay-content-wrapper .shape-editor-options-description {
  padding: 10px;
}

.overlay-content-wrapper .measure-options {
  width: fit-content;
  margin-right: 10px;
}

.overlay-content-wrapper .annotation-options,
.overlay-content-wrapper .measure-button-text-wrapper .measure-button-text,
.overlay-content-wrapper .editor-button-text,
.overlay-content-wrapper .eraser-button-text {
  width: fit-content;
  color: white;
}

.overlay-content-wrapper .annotation-colors-description,
.measure-colors-description {
  margin: 0;
}

.overlay-content-wrapper .annotation-colors-wrapper {
  margin-top: 10px;
}

.overlay-content-wrapper .editor-button-wrapper {
  flex-direction: row;
  margin-top: 24px;
  margin-right: 1%;
}

.overlay-content-wrapper .light-button,
.overlay-content-wrapper .dark-button {
  border: none;
  color: white;
  height: 50px;
  background-color: #444444;
  border-radius: 8px;
}

.overlay-content-wrapper .dark-button {
  margin-right: 0px;
}