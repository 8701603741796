.shape-editor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.shape-editor-options-description {
  margin-bottom: 10px;
  margin-top: 10px;
}

.shape-editor-wrapper svg {
  height: 100%;
  width: 75%;
}

.shape-editor-options {
  display: flex;
  height: 100%;
  width: 100%;
}

.enable-forecasting input {
  margin: 0 5px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.add-measure-button {
  border: none;
  background-color: transparent;
  color: rgb(5, 98, 138);
}

.add-measure-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  width: 14px;
  height: 14px;
}

.text-button-wrapper {
  margin-right: 5%;
}

.shape-editor-controls,
.annotation-options {
  /*border-right: 1px solid rgba(255, 255, 255, 0.5);*/ /* needed if there is an add text button to the right */
}

.shape-editor-controls {
  padding-right: 2%;
  margin-right: 2%;
}

.annotation-options {
    margin-right: 1%;
}

.measure-buttons-wrapper {
  margin-top: 5px;
  margin-bottom: 2px;
  width: 100%;
}

.measure-button,
.editor-button,
.eraser-button {
  background-color: transparent;
  border: none;
  height: fit-content;
  display: grid;
  grid-template-columns: 25px auto;
}

.measure-button-swatch-holder,
.editor-icon-holder,
.eraser-icon-holder {
  width: 48px;
  height: 48px;
  position: relative;
}

.measure-button-swatch,
.annotation-color {
  width: 40px;
  height: 40px;
}

.measure-button-swatch {
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 50%;
}

.measure-button-swatch.selected {
  border: 2px solid #ccc; /* Light grey border to indicate selection */
  box-shadow: 0 0 5px rgba(255,255,255,0.6); /* Optional shadow for emphasis */
  border-radius: 50%; /* Ensures the border follows the circular shape */
}

.measure-button-swatch-2 {
  background-color: rgb(254, 40, 43);
}

.measure-button-swatch-1 {
  background-color: rgb(165, 102, 232);
}

.measure-button-text-wrapper,
.editor-button-text-wrapper,
.eraser-button-text-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;
  line-height: 20px;
}

.shape-editor-options,
.measure-button-text,
.editor-button-text,
.eraser-button-text {
  text-align: left;
  font-size: 14px;
}

.shape-editor-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  max-width: 100%;
  height: fit-content;
}

.measure-button-text.selected,
.editor-button-text.selected,
.eraser-button-text.selected {
  font-weight: 900;
}

.editor-button-wrapper {
  margin-top: 20px;
}

.editor-icon-holder svg {
  fill: #777;
}

.editor-icon-holder.selected svg {
  fill: white;
}

.editor-axis,
.editor-axis-title {
  cursor: default;
}

.editor-axis-title {
  font-size: 12px;
  font-weight: 500;
  font-family: "TableauBook-Bold";
}

.editor-point {
  cursor: grab;
}

.annotation-colors-description {
  margin-bottom: 10px;
}

.annotation-colors-wrapper {
  display: flex;
  gap: 10px;
}

.annotation-color {
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  border-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
}

.annotation-color.selected {
  border: 2px solid #999; /* Light border for selection */
  box-shadow: 0 0 5px rgba(255,255,255, 0.6); /* Optional shadow for emphasis */
}

.annotation-color-black {
  background-color: #000; /*rgb(99, 99, 99);*/
}

.eraser-icon-holder svg {
  fill: #777;
}

.eraser-icon-holder.selected svg {
  fill:white;
}


.light-button,
.dark-button {
  border-radius: 8px;
  height: 30px;
  width: fit-content;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.light-button {
  background-color: transparent;
  color: #0350d9;
  border: 1px solid black;
}

.dark-button {
  background-color: #066afe;
  color: #ffffff;
  border: none;
}

.sketch-canvas {
  width: fit-content;
  height: fit-content;
  background-color: transparent;
}

.sketch-canvas > canvas {
  touch-action: none;
  transform: scale(
    1,
    -1
  ); /* need to invert the Y since Canvas thinks (0,0) is in the top left corner, not bottom left */
}
