.line-chart-wrapper {
    width: 75%;
    height: 100%;
    position: relative;
}

.line-chart {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;
  }
  
  .fancy-spinner {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7b68ee; /* fancy purple/blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  .spinner-text {
    margin-top: 12px;
    font-size: 16px;
    color: #555;
    font-weight: 500;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }