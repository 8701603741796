.dialog-floater {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(0,0,0,0.05)
}

.dialog-wrapper {
    padding: 10px 23px 10px 23px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 1px;
    box-shadow: rgba(51, 51, 51, 0.15) 0px 2px 16px 0px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.dialog-title {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 12px;
    cursor: default;
    vertical-align: middle;
    padding: 10px 10px 10px 0px;
}

.dialog-content-wrapper {
    flex: 1;
}

.dialog-footer {
    display: flex; 
    justify-content: space-between;
    margin-top: 30px;
}

.dialog-footer-right {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
}

.query-dropdown-wrapper, .dark-button.query-button-svg-wrapper  {
    width: 50px;
    min-width: 50px;
    margin: 0;
}

.query-button-svg-wrapper {
    margin: 0;
}

.query-dropdown-wrapper {
    margin-left: 3px;
    position: relative;
}

.query-button-svg-wrapper svg {
    position: relative;
    top: 0;
    left: 0;
    fill: white;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
}

.hidden {
    display: none;
}

.query-button-options-list {
    position: absolute;
    left: 0;
    width: 200px;
}

.query-button-options-list ul {
    margin-top: 2px;
    list-style-type: none;
    padding: 0;
    text-align: left;
    background-color: #444444;
}

.dialog-footer button:hover {
    background-color: #666666;
}

.save-options-button {
    background: inherit;
    color: inherit;
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px;
    text-align: left;
    cursor: pointer;
}

.canvas {
    border: 1px solid #77777752;
}

.small-button {
    padding: 6px 10px; 
    font-size: 12px; 
    border-radius: 8px; 
    margin-right: 10px; 
  }

  .dialog-footer button {
    margin-right: 10px; /* Add space between buttons */
  }
  
  .dialog-footer button:last-child {
    margin-right: 0; /* Remove margin for the last button */
  }