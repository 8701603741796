@font-face {
	font-family: "TableauBook";
	src: url("../public/fonts/TableauBook-Regular.ttf");
}

@font-face {
	font-family: "TableauBook-Bold";
	src: url("../public/fonts/TableauBook-Bold.ttf");
}

html, body, div, button {
  font-family: "TableauBook", Verdana, Geneva, Tahoma, sans-serif;
}

html, body {
  overflow: hidden;
  height: 100vh; /* Ensure the body takes up the full height */
  width: 100vw; /* Ensure the body takes up the full width */
  margin: 0; /* Remove default margin */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
