.tabs {
  display: flex;
  flex-shrink: 0;
  font-size: 10px;
  height: 7%;
  user-select: none;
  cursor: default;
}

.tab {
  flex: 1 0 50%;
  background-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.tab:hover {
  background-color: rgb(240, 240, 240)
}

.tab-selected {
    border-bottom: 3px solid #5c5c5c;
}

.tab-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-right: 24px;
  padding-left: 24px;
}

.tab-text-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tab-text {
  font-family: "TableauBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #000;
}

.tab-icon {
  width: 30px; 
  height: auto; 
  margin-right: 8px; 
  vertical-align: middle; 
}
