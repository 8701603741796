.shape-library {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  width: 90%;
  height: 93%;
}

.new-shape-button {
  border: none;
  background: none;
  color: rgb(5,98,138);
  width: 100%;
  padding: 20px;
  font-size: 16px;
  line-height: 16px;
  display: inline-flex;
  justify-content: center;
}

.new-shape-button svg {
  margin-right: 2px;
}

.shape-library-buttons-wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 5px;
  height: 10%;
  padding: 0 20px 0 20px;
}

.shape-button {
  flex: 1;
}

.shape-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-left:auto;
  margin-right: auto;
}

.shape-item-wrapper {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-bottom: 20px;
}

.shape-item-wrapper canvas {
  border: none;
}

.shape-item {
  border: 1px solid rgb(216, 216, 216);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  border-radius: 1px;
  border: 1px solid #77777752;
  width: 100%;
  height: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.shape-selected {
  border: 1px solid #777777;
}

.shape-library .sketch-canvas {
  padding: 0;
  margin: 0;
}

.shape-library-header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}

.search-button {
  background-color: #066AFE;
  color: #ffffff;
  border: none;
  padding: 5px 40px;
  width: 170px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.sketch-cursor-wrapper {
  margin-right: 5px;
}

.sketch-cursor-wrapper svg {
  width: 16px; 
  height: 16px;
  margin-top: 3px;
}