.container {
  height: 96vh;
}

.header {
  height: 8%; /* this plus .main-content height should equal 100% */
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.no-results-found-wrapper {
  position: relative;
}

.no-results-found {
  position: absolute;
  font-weight: 700;
  background-color: white;
  top: 75px;
  left: -75px;
  width: 150px;
  padding: 5px;
  border: 2px solid black;
  text-align: center;
  z-index: 500;
}

.dendrogram-slider-wrapper label {
  width: 100%;
  margin-left: 15px;
}

input.dendrogram-slider[type=range] {
  margin: 5px 0px 5px 10px;
  direction: rtl;
}

input.results-filter-slider[type=range] {
  margin: 5px 0px 5px 10px;
}

.top-left {
  display: flex;
  height: 30%;
  min-height: 100px; /* Adjust as needed */
  border-bottom: 1px solid black;
}

.top-left-left {
  width: 50%;
  min-width: 100px; /* Adjust as needed */
  border-right: 1px solid black;
  background-color: lightblue;
}

.top-left-right {
  width: 50%;
  background-color: lightgreen;
}

.main-content {
  background-color: white; /*lightcoral;*/
  display: flex;
  height: 92%;  /* this plus .header height should equal 100% */
}

.bottom-left {
  height: 10%;
  background-color: lightgoldenrodyellow;
  min-height: 100px; /* Adjust as needed */
}

.tableau-viz-component {
  width: 100%; 
  height: 100%;
  overflow-y: scroll; /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.map-main-wrapper {
  width: 75%;
  height: 100%;
  display: flex;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-legend {
  width: 20%; /* height is set in Layout.tsx based on canvasHeight */
  border: 1px solid red;
  display: flex;
  flex-direction: column;
}

.sidebar {
  width: 25%;
  border-left: 5px solid rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
}

.sidebar .tabs {
  flex-grow: 0;
}

.sidebar .shape-library {
  flex-grow: 1;
}

.chat, .shape-library {
  width: 100%;
  max-width: 100%;
}

.logo {
  width: 70%; 
  height: auto;
}